import React, { useState, useEffect } from "react";
import {
    DeleteOutlined,
} from "@ant-design/icons";
import {
    Select,
    Button,
    DatePicker,
} from "antd";
const { RangePicker } = DatePicker;

import dayjs from 'dayjs';

const convertDateFormat = (inputDate) => {
    const date = new Date(inputDate);

    const pad = (num) => (num < 10 ? '0' + num : num);

    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const year = date.getFullYear();
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());

    return `${month}/${day}/${year} ${hours}:${minutes}`;
}


const ScheduleEditor = ({ playlistSchedules, refreshData, notifyNode }) => {
    const [scheduleList, setScheduleList] = useState([]);
    const [scheduleIndex, setScheduleIndex] = useState(null);
    const [times, setTimes] = useState([null, null]);

    useEffect(() => {
        // Fetch Schedule List
        getPlaylistSchedules();
    }, []);

    useEffect(() => {
        // Reset scheduleIndex when scheduleList chanes
        setScheduleIndex(null);
    }, [scheduleList]);

    useEffect(() => {
        // Update times when scheduleIndex changes
        if (scheduleIndex !== null && scheduleList.length > 0) {
            setTimes([
                dayjs(scheduleList[scheduleIndex].start_time),
                dayjs(scheduleList[scheduleIndex].end_time),
            ]);
        }
    }, [scheduleIndex, scheduleList]);

    useEffect(() => {
        console.log(times);
    }, [times]);

    const getPlaylistSchedules = async () => {
        const url = new URL("/api/user/playlist/getPlaylistSchedule", window.location.origin);
        const sessionToken = localStorage.getItem("sessionToken");
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionToken}`,
            },
        });

        if (!response.ok) {
            alert("Failed to get playlist schedules");
            return;
        }

        const data = await response.json();
        setScheduleList(data.playlistSchedules);
    }

    const onScheduleSave = () => {
        // edit Schedule
        const url = new URL("/api/system/playlist/playlistSchedule", window.location.origin);
        const sessionToken = localStorage.getItem("sessionToken");
        fetch(url, {
            method: "POST",
            body: JSON.stringify({
                playlistScheduleId: scheduleList[scheduleIndex].playlist_schedule_ID,
                startTimestamp: times[0] ? times[0].format("YYYY-MM-DD HH:mm:ss") : null,
                endTimestamp: times[1] ? times[1].format("YYYY-MM-DD HH:mm:ss") : null,
            }),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionToken}`,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    alert("Failed to create schedule");
                    return;
                }
            })
            .catch((error) => {
                console.error("Error creating schedule: ", error);
            });

        // Clear form
        setScheduleIndex(null);
        setTimes([null, null]);

        // Refresh data
        notifyNode(scheduleList[scheduleIndex - 1].node_ID);
        getPlaylistSchedules();
        refreshData();
    }

    const onScheduleDelete = () => {
        // Delete Schedule
        const url = new URL("/api/system/playlist/playlistSchedule", window.location.origin);
        const sessionToken = localStorage.getItem("sessionToken");
        fetch(url, {
            method: "DELETE",
            body: JSON.stringify({
                playlistScheduleId: scheduleList[scheduleIndex].playlist_schedule_ID,
            }),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionToken}`,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    alert("Failed to create schedule");
                    return;
                }
            })
            .catch((error) => {
                console.error("Error creating schedule: ", error);
            });

        // Clear form
        setScheduleIndex(null);
        setTimes([null, null]);

        // Refresh data
        notifyNode(scheduleList[scheduleIndex - 1].node_ID);
        getPlaylistSchedules();
        refreshData();
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 20,
                marginLeft: 20,
                width: "100%",
            }}
        >
            <h3>Edit Schedule</h3>
            <div>
                <Select
                    defaultValue="Select Schedule"
                    style={{
                        width: 400,
                        paddingLeft: "8.33%", // 2.5% divided by 30%
                        marginBottom: 20,
                    }}
                    onSelect={(value) => setScheduleIndex(value)}
                    value={scheduleIndex}
                >
                    {scheduleList.map((schedule, index) => (
                        <Select.Option key={index} value={index}>
                            {schedule.node_name} - {schedule.playlist_name}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 10,
                    width: "100%",
                    gap: 100,
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <h4
                        style={{
                            width: "18%",
                        }}
                    >
                        Times:{" "}
                    </h4>
                    <RangePicker
                        showTime={{
                            format: "HH:mm",
                        }}
                        format="MM/DD/YYYY HH:mm"
                        onChange={(value, dateString) => {
                            console.log(value);
                            setTimes(value);
                        }}
                        value={times}
                    />
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 60,
                    marginBottom: 20,
                    width: "100%",
                    gap: 20,
                }}
            >
                <Button
                    style={{
                        width: "25%",
                    }}
                    disabled={!times[0] || !times[1] || scheduleIndex === null}
                    onClick={onScheduleSave}
                >
                    Save
                </Button>
                <Button
                    icon={<DeleteOutlined />}
                    disabled={scheduleIndex === null}
                    onClick={onScheduleDelete}
                ></Button>
            </div>
        </div>
    );
}

export default ScheduleEditor;