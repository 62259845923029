import { useState } from 'react'
import LandingTree from './LandingTree'
import LandingHead from './LandingHead'
import Pie from './Pie'
import StartingButtons from './StartingButtons'
import Schedule from './Schedule'
import './styles/Landing.css'

function Landing(){
    return(
        <>
            <head>
                <meta charset="UTF-8"></meta>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <title>Post Login Landing Page</title>
                <link rel="stylesheet" href="style.css"></link>
            </head>
            <header className='header'>
                <LandingHead />
            </header>
            <body className='body'>
                <main className='main'>
                    <aside className='aside'>
                        <LandingTree />
                    </aside>
                    <section className='pie-chart'>
                        <Pie />
                    </section>
                    <section className="button-section">
                        <StartingButtons />
                    </section>
                    <section className='schedule'>
                        <Schedule />
                    </section>
                </main>
                <footer>
                    
                </footer>
            </body>
        </>
    )
}

export default Landing