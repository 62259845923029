import React, { useState } from "react";
import {
    Select,
    Button,
    DatePicker,
} from "antd";
const { RangePicker } = DatePicker;
import dayjs from "dayjs";

const ScheduleCreator = ({ nodeList, playlistList, refreshData, notifyNode }) => {
    const [node, setNode] = useState(null);
    const [playlist, setPlaylist] = useState(null);
    const [times, setTimes] = useState([null, null]);

    const onScheduleCreate = () => {
        // Create Schedule
        const url = new URL("/api/system/playlist/playlistSchedule", window.location.origin);
        const sessionToken = localStorage.getItem("sessionToken");
        fetch(url, {
            method: "PUT",
            body: JSON.stringify({
                nodeId: node,
                playlistId: playlist,
                startTimestamp: times[0] ? times[0].format("YYYY-MM-DD HH:mm:ss") : null,
                endTimestamp: times[1] ? times[1].format("YYYY-MM-DD HH:mm:ss") : null,
            }),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionToken}`,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    alert("Failed to create schedule");
                    return;
                }
            })
            .catch((error) => {
                console.error("Error creating schedule: ", error);
            });

        // Clear form
        setNode(null);
        setPlaylist(null);
        setTimes([null, null]);

        // Refresh data
        notifyNode(node);
        refreshData();
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 20,
                marginLeft: 20,
                width: "100%",
            }}
        >
            <h3>Create New Schedule</h3>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 10,
                    width: "100%",
                    gap: 100,
                }}
            >
                <div
                    style={{
                        width: "50%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <h4
                        style={{
                            width: "40%",
                        }}
                    >
                        Node:{" "}
                    </h4>
                    <Select
                        defaultValue="Select Node"
                        value={node}
                        style={{
                            width: "60%",
                        }}
                        onSelect={(value) => setNode(value)}
                    >
                        {nodeList.map((node) => {
                            return (
                                <Select.Option value={node.id}>
                                    {node.name}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </div>
                <div
                    style={{
                        width: "50%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <h4
                        style={{
                            width: "40%",
                        }}
                    >
                        Playlist:{" "}
                    </h4>
                    <Select
                        defaultValue="Select Playlist"
                        value={playlist}
                        style={{
                            width: "60%",
                        }}
                        onSelect={(value) => setPlaylist(value)}
                    >
                        {playlistList.map((playlist) => {
                            return (
                                <Select.Option value={playlist.id}>
                                    {playlist.name}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 10,
                    width: "100%",
                    gap: 100,
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <h4
                        style={{
                            width: "18%",
                        }}
                    >
                        Times:{" "}
                    </h4>
                    <RangePicker
                        value={times}
                        showTime={{
                            format: "HH:mm",
                        }}
                        format="MM/DD/YYYY HH:mm"
                        onChange={(value, dateString) => {
                            setTimes(value);
                        }}
                    />
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 60,
                    marginBottom: 20,
                    width: "100%",
                }}
            >
                <Button
                    style={{
                        width: "25%",
                    }}
                    disabled={!times[0] || !times[1] || !node || !playlist}
                    onClick={onScheduleCreate}
                >
                    Schedule Playlist
                </Button>
            </div>
        </div>
    );
};

export default ScheduleCreator;