import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Text, Heading, Center, Input, HStack, Button, Image, Stack, Flex} from '@chakra-ui/react'
import { InputGroup } from '../../../components/ui/input-group'
import { PasswordInput } from '../../../components/ui/password-input'
import { Checkbox } from '../../../components/ui/checkbox'
import { Field } from '../../../components/ui/field';
import { Link } from 'react-router-dom'
import { MdOutlineMail } from "react-icons/md"
import { MdOutlineLock } from 'react-icons/md'
import logo from '../../../shared/assets/logo.png'
import { Toaster, toaster } from "../../../components/ui/toaster"
//import '../../../shared/ui/styles/Login.css';

const Login = () => {  

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [checkEmail, checkEmailToggle] = useState(true);
  const [checkPassword, checkPasswordToggle] = useState(true);
  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const navigate = useNavigate();

  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const submitButtonRef = useRef(null);

  const initialRender = useRef({emailReady: false, passwordReady: false});

  const navToRegistration = () => {
    navigate('/RegistrationLogin');
  };
  
  const navToDashboard = () => {
    navigate('/Dashboard/Landing');
  };

  const onCheckClick = () => {
    setRememberMe(!rememberMe);
  };

  useEffect(() => {
    const handleEnterKey = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        submitButtonRef.current?.click();
      }
    };

    if (emailInputRef.current) {
      emailInputRef.current.addEventListener("keydown", handleEnterKey);
    }
    if (passwordInputRef.current) {
      passwordInputRef.current.addEventListener("keydown", handleEnterKey);
    }

    return () => {
      if (emailInputRef.current) {
        emailInputRef.current.removeEventListener("keydown", handleEnterKey);
      }
      if (passwordInputRef.current) {
        passwordInputRef.current.removeEventListener("keydown", handleEnterKey);
      }
    };
  }, []);  

  useEffect(() => {
    const sessionToken = localStorage.getItem('sessionToken');
    if (sessionToken) {
      const url = new URL('/api/auth/sessionVerify', window.location.origin);
      url.searchParams.append('token', sessionToken);

      fetch(url, {
        method: 'GET',
        headers: { 'Accept': 'application/json' },
      })
      .then(response => response.json())
      .then(data => {
        if (data.expired) {
          console.log("Session expired, please log in again.");
        } else {
          navToDashboard();
        }
      })
      .catch(error => {
        console.error("Error verifying session:", error);
      });
    }
  }, []);


  useEffect(() => {
    setEmailError("");
    if(initialRender.current["emailReady"]){
        if(email === ""){
            setEmailError("Please enter an email or username");
            checkEmailToggle(false);
            setEmailValid(false);
        }else{
            checkEmailToggle(true);
            setEmailValid(true);
        }
    }
    initialRender.current["emailReady"] = true;
  },[email, trigger]);

  useEffect(() => {
    setPasswordError("");
    if(initialRender.current["passwordReady"]){
        if(password === ""){
            setPasswordError("Please enter a password");
            checkPasswordToggle(false);
            setPasswordValid(false);
        }else{
            checkPasswordToggle(true);
            setPasswordValid(true);
        }
    }
    initialRender.current["passwordReady"] = true;
  },[password, trigger]);



  const onButtonClick = () => {

    if(!emailValid || !passwordValid){
      setTrigger(!trigger);
      return;
    }

    const url = new URL('/api/auth/loginVerify', window.location.origin);

    fetch(url, {
      method: 'POST',
      headers: { 
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        password: password,
        rememberMe: rememberMe
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.token) {
        localStorage.setItem('sessionToken', data.token);
        navToDashboard();
      } else {
        setPasswordError("Invalid email or password");
      }
    })
    .catch(error => {
      setPasswordError("Invalid email or password");
      console.log(error)
      toaster.create({
          title: "Uh oh",
          description: "Invalid email or password",
          type: "error",
          duration: "3000"
      })
    });
  };
  
  return (
    /*
    <div className='body-box'>
      <div className="login-box">
        <h2>LOGIN</h2>
        <form action="" method="">
          <div className="user-box">
            <input
              ref={emailInputRef}
              value={email}
              placeholder='Username or Email'
              onChange={ev => setEmail(ev.target.value)}
              className="user-input"
            />
            <label className='errorLabel'>{emailError}</label>
          </div>
          <div className="user-box">
            <input
              ref={passwordInputRef}
              value={password}
              placeholder='Password'
              onChange={ev => setPassword(ev.target.value)}
              className='pass-input'
              type='password'
            />
            <label className='errorLabel'>{passwordError}</label>
          </div>
          <input
            onClick={onCheckClick}
            className="inputCheck"
            name='check'
            id='check'
            type="checkbox"
            checked={rememberMe}
            onChange={onCheckClick}
          />
          <label className='checkLabel'>Remember Me</label><br></br>
          <input
            ref={submitButtonRef}
            onClick={onButtonClick}
            className="inputButton"
            type="button"
            value="Submit"
          />
        </form>
        <div>
          <button className="navButton" onClick={navToRegistration}>Register this Screen</button>
        </div>
      </div>
      <div className="picture-box"></div>
    </div>
    */
    <Box bg={"gray.100"} minH={"100vh"} p={6} w={"full"} alignContent={"center"}>
      <Center>
        <HStack maxW={"3/6"} minW={"3/6"} maxH={"3/5"} style={{backgroundColor: "#011936"}}
          rounded={"4xl"} gap={0} p={0} shadow={"xl"}>
            <Box style={{backgroundColor: "#011936"}} minW={"3/5"} rounded={"4xl"}>
              <Stack gap={8} paddingLeft={8} paddingRight={16} w={"full"} paddingTop={8} paddingBottom={4}>
                <Heading as={"h1"} margin={"auto"} fontSize={"4xl"} fontFamily={"Esteban"}>
                  Login
                </Heading>
                <Field invalid={!checkEmail} errorText={emailError}>
                  <InputGroup startElement={<MdOutlineMail />} w={"full"}>
                    <Input placeholder={"Email or Username"} bg={"gray.100"} rounded={"xl"} p={4} color={"black"} fontSize={20}
                      w={"full"} ref={emailInputRef} onChange={ev => setEmail(ev.target.value)}>
                    </Input>
                  </InputGroup>
                </Field>
                <Field invalid={!checkPassword} errorText={passwordError}>
                  <InputGroup startElement={<MdOutlineLock />} w={'full'}>
                    <PasswordInput placeholder={"Password"} bg={"gray.100"} rounded={"xl"}
                      p={4} color={"black"} fontSize={20} w={'full'} ref={passwordInputRef} onChange={ev => setPassword(ev.target.value)}>                      
                    </PasswordInput>
                  </InputGroup>
                </Field>
                <Box w={"full"} alignContent={"flex-start"}>
                <Checkbox bg={"gray.100"} rounded={"lg"} onClick={onCheckClick} checked={rememberMe}></Checkbox>
                <Text color={"white"} as={'span'} paddingLeft={4}>
                        Remember Me
                </Text>
                </Box>
                <Button rounded={"xl"} style={{ backgroundColor: "#f9DC5C"}} fontFamily={"Esteban"} fontSize={"2xl"} color={'black'}
                  addingLeft={10} paddingRight={10} w={'3/5'} margin={'auto'} ref={submitButtonRef} onClick={onButtonClick}>
                  Submit
                </Button>
                <div style={{backgroundColor: '#465362', height: '2px'}}></div>
                <HStack w={'full'}>
                  <Link to={'/'}>
                    <Text _hover={{color: '#f9DC5C'}}>
                      &#8592;Back
                    </Text>
                  </Link>
                  <Flex marginLeft={'auto'}>
                    <Text as={'span'}>
                        Create Account{'\n'}
                      <Link to={'/createAccount'}>
                        <Text as={'sapn'} style={{ color: "#f9DC5C"}} _hover={{textDecoration: 'underline'}} >
                          here
                        </Text>
                      </Link>
                    </Text>
                  </Flex>
                </HStack>
              </Stack>
            </Box>
            <Box style={{backgroundColor: "#B1BFFF"}} rounded={"4xl"} h={"calc(100vh*.7)"} alignContent={"center"} fontFamily={'Esteban'}>
              <Image src={logo}/>
            </Box>
        </HStack>
      </Center>
      <Toaster />
    </Box>
  );
}

export default Login;
