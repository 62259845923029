import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Center, HStack, Heading, Flex, IconButton, Button, Text} from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
//import '../../../shared/ui/styles/Login.css';

function RegistrationLogin({useVal, useSet}) {
    const [sixDigitCode, setCode] = useState("");
    const [token, setToken] = useState("");
    const navigate = useNavigate();

    const navToDisplay = () => {
        navigate('/digitalSign');
    };

    const closeSelf = () => {
        useSet(!useVal)
    }

    useEffect(() => {
        // Check for an existing token in localStorage
        const cachedToken = localStorage.getItem('nodeToken');
        
        if (cachedToken) {
            // If a token is already cached, use it directly
            setToken(cachedToken);
            setCode(localStorage.getItem('sixDigitCode')); // Retrieve cached code as well if stored
        } else {
            // If no token is cached, call the worker API to get new token and code
            const enrollmentURL = new URL('/api/enroll/nodeEnrollment_Node', window.location.origin);

            fetch(enrollmentURL, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => {
                // Save the token and code in state
                setToken(data.token);
                setCode(data.code);

                // Cache the token and code in localStorage for future use
                localStorage.setItem('nodeToken', data.token);
                localStorage.setItem('sixDigitCode', data.code);
            })
            .catch(error => console.error("Error fetching the enrollment code:", error));
        }
    }, []); // Empty dependency array ensures this only runs on mount

    return (
        /*
        <div className='body-box'>
            <div className="login-box">
                <h2>ENROLL A SCREEN</h2>
                <div className='code'>
                    {sixDigitCode || "Getting your code..."}
                </div> 
                <div className='user-box'>
                    <p>Sign in to the portal and log in to your account. You can then type this code in to enroll this screen.</p>
                    <p>Then you can start displaying your masterpieces!</p>
                </div>
            </div>
            <button class="navButton" onClick={navToDisplay}>Ready to Display</button>
        </div> 
        */
        <Box h={'full'} w={'full'} pos={'fixed'} bg={'gray.800/60'} alignContent={'center'}>
            <Center>
                <Box minW={"5/12"} maxW="5/12" style={{backgroundColor: "#011936"}} pos={'relative'} rounded={'2xl'} p={8}>
                    <HStack minW={'full'} pos={'relative'} p={2}>
                        <Heading as={"h1"} fontSize={"5xl"} pos={'relative'} margin={'auto'}>
                            Enroll This Screen
                        </Heading>
                        <Flex minW={'full'} pos={'absolute'} justify={'flex-end'}>
                            <IconButton bg={'transparent'} color={'red.400'} size={'xl'} onClick={closeSelf}>
                                <MdClose style={{width: "100%", height: "100%"}}/>
                            </IconButton>
                        </Flex>
                    </HStack>
                    <Box minW={'full'}>
                        <Center>
                            <Heading as={'h2'} fontSize={(sixDigitCode == "") ? '4xl' : '7xl'} marginTop={10}>
                                {sixDigitCode || "Getting your code..."}
                            </Heading>
                        </Center>
                        <Center>
                            <Text m={12} fontSize={16}>
                                Sign in to the portal and log in to your account. You can then type this code in to enroll this screen. Then you can start displaying your masterpieces!
                            </Text>
                        </Center>
                        <Center>
                            <Button color={'black'} shadow={'lg'} padding={4} bg={'#f9DC5C'} fontSize={32} fontWeight={'bold'} rounded={'2xl'}
                                paddingLeft={8} paddingRight={8} onClick={navToDisplay}>
                                Display Now
                            </Button>
                        </Center>
                    </Box>
                </Box>
            </Center>
        </Box>      
    );
}

export default RegistrationLogin;

/*
<Box alignContent={'center'} justifyContent={'center'} h={'100vh'}>
            <Center>
                <Box minW={"3/5"} maxW="3/5" style={{backgroundColor: "#011936"}} pos={'relative'} rounded={'2xl'} p={8}>
                    <HStack minW={'full'} pos={'relative'} p={2}>
                        <Heading as={"h1"} fontSize={"5xl"} pos={'relative'} margin={'auto'}>
                            Enroll This Screen
                        </Heading>
                        <Flex minW={'full'} pos={'absolute'} justify={'flex-end'}>
                            <IconButton bg={'transparent'} color={'red.400'} size={'xl'}>
                                <MdClose style={{width: "100%", height: "100%"}}/>
                            </IconButton>
                        </Flex>
                    </HStack>
                    <Box minW={'full'}>
                        <Center>
                            <Heading as={'h2'} fontSize={'8xl'} m={8}>
                                sevjyq
                            </Heading>
                        </Center>
                        <Center>
                            <Text m={12} fontSize={16}>
                                Sign in to the portal and log in to your account. You can then type this code in to enroll this screen. Then you can start displaying your masterpieces!
                            </Text>
                        </Center>
                        <Center>
                            <Button color={'black'} shadow={'lg'} padding={4} bg={'#f9DC5C'} fontSize={32} fontWeight={'bold'} rounded={'2xl'}
                                paddingLeft={8} paddingRight={8}>
                                Display Now
                            </Button>
                        </Center>
                    </Box>
                </Box>
            </Center>
        </Box>
        */