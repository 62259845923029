import { useNavigate } from "react-router-dom";
import logo from "../../../shared/assets/logo.png";

import React, { useState } from "react";
import NodeScheduleDisplay from "./NodeScheduleDisplay";
import PlaylistCreator from "./PlaylistCreator";
import PlaylistEditor from "./PlaylistEditor";
import ScheduleCreator from "./ScheduleCreator";
import ScheduleEditor from "./ScheduleEditor";

import {
    DesktopOutlined,
    FileOutlined,
    PieChartOutlined,
    TeamOutlined,
    PlaySquareOutlined,
} from "@ant-design/icons";
import {
    Breadcrumb,
    Layout,
    Menu,
    theme,
    Tabs,
} from "antd";
const { Header, Content, Footer, Sider } = Layout;

const NodePlaylistManager = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [nodeHierarchy, setNodeHierarchy] = useState(null);
    const [nodeList, setNodeList] = useState([]);
    const [playlistHierarchy, setPlaylistHierarchy] = useState(null);
    const [playlistList, setPlaylistList] = useState([]);
    const [playlistSchedules, setPlaylistSchedules] = useState(null);
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    React.useEffect(() => {
        getHierarchies();
        getSchedules();
    }, []);

    React.useEffect(() => {
        if (nodeHierarchy) {
            const nodeList = [];
            const nodeQueue = [nodeHierarchy];
            while (nodeQueue.length > 0) {
                const node = nodeQueue.shift();
                if (node.type === "node")
                    nodeList.push({ id: node.id, name: node.name });
                if (node.children) 
                    nodeQueue.push(...node.children);
            }
            setNodeList(nodeList);
        }

    }, [nodeHierarchy]);
    
    React.useEffect(() => {
        if (playlistHierarchy) {
            const playlistList = [];
            const playlistQueue = [playlistHierarchy];
            while (playlistQueue.length > 0) {
                const playlist = playlistQueue.shift();
                if (playlist.type === "playlist")
                    playlistList.push({ id: playlist.id, name: playlist.name });
                if (playlist.children) 
                    playlistQueue.push(...playlist.children);
            }
            setPlaylistList(playlistList);
            console.log(playlistList);
        }
    }, [playlistHierarchy]);
    
    const getHierarchies = async () => {
        // Fetch Node Hierarchy
        const url = new URL("/api/user/node/getNodeHierarchy", window.location.origin);
        url.searchParams.append("headId", null);
        url.searchParams.append("levels", 20);
        const sessionToken = localStorage.getItem("sessionToken");
        fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionToken}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setNodeHierarchy(data);
            })
            .catch((error) => {
                console.error("Error fetching node hierarchy: ", error);
            });

        // Fetch Playlist Hierarchy
        const playlistUrl = new URL("/api/user/playlist/getPlaylistHierarchy", window.location.origin);
        playlistUrl.searchParams.append("headId", null);
        playlistUrl.searchParams.append("levels", 20);
        fetch(playlistUrl, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionToken}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setPlaylistHierarchy(data);
            })
            .catch((error) => {
                console.error("Error fetching playlist hierarchy: ", error);
            }); 
    };

    const getSchedules = async () => {
        const url = new URL("/api/user/playlist/getPlaylistSchedule", window.location.origin);
        const sessionToken = localStorage.getItem("sessionToken");
        fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionToken}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data.playlistSchedules);
                setPlaylistSchedules(data.playlistSchedules);
            })
            .catch((error) => {
                console.error("Error fetching playlist schedule: ", error);
            });
    }

    const refreshData = () => {
        getHierarchies();
        getSchedules();
    }

    const notifyNode = async (nodeId) => {
        const sessionToken = localStorage.getItem('sessionToken');

        const nodeTokenUrl = new URL('/api/user/node/getNodeToken', window.location.origin);
        nodeTokenUrl.searchParams.append('nodeID', nodeId);
        const nodeTokenResponse = await fetch(nodeTokenUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${sessionToken}`,
            },
        });

        const nodeTokenData = await nodeTokenResponse.json();
        const nodeToken = nodeTokenData.nodeToken;

        const socketUrl = new URL('https://durable-object-starter.10951246.workers.dev');
        fetch(socketUrl, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${sessionToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                message: "refresh",
                token: nodeToken,
            }),
        })
    }

    function getItem(label, key, icon, children) {
        return {
            key,
            icon,
            children,
            label,
        };
    }

    const navItems = [
        getItem("Dashboard", "1", <PieChartOutlined />),
        getItem("Displays", "2", <DesktopOutlined />),
        getItem("Playlists", "3", <PlaySquareOutlined />),
        getItem("Team", "sub2", <TeamOutlined />, [
            getItem("Team 1", "6"),
            getItem("Team 2", "8"),
        ]),
        getItem("Files", "9", <FileOutlined />),
    ];

    const tabItems = [
        {
            key: "1",
            label: "Create Playlist",
            children: <PlaylistCreator 
                rootDir={1}
                refreshData={refreshData}
            />,
        },
        {
            key: "2",
            label: "Edit Playlist",
            children: <PlaylistEditor
                playlists={playlistList}
                refreshData={refreshData}
            />,
        },
        {
            key: "3",
            label: "Create Schedule",
            children: <ScheduleCreator
                nodeList={nodeList}
                playlistList={playlistList}
                refreshData={refreshData}
                notifyNode={notifyNode}
            />,
        },
        {
            key: "4",
            label: "Edit Schedule",
            children: <ScheduleEditor 
                playlistSchedules={playlistSchedules}
                refreshData={refreshData}
                notifyNode={notifyNode}
            />
        }
    ];

    return (
        <Layout
            style={{
                minHeight: "100vh",
            }}
        >
            <Sider
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        height: 64,
                    }}
                    className="demo-logo-vertical"
                >
                    <img
                        style={{
                            width: 50,
                            height: 50,
                            margin: 20,
                        }}
                        src={logo}
                        alt="logo"
                    />
                </div>
                <Menu
                    theme="dark"
                    defaultSelectedKeys={["3"]}
                    mode="inline"
                    items={navItems}
                />
            </Sider>
            <Layout>
                <Header
                    style={{
                        padding: 0,
                        background: colorBgContainer,
                    }}
                />
                <Content
                    style={{
                        margin: "0 16px",
                    }}
                >
                    <Breadcrumb
                        style={{
                            margin: "16px 0",
                        }}
                    >
                        <Breadcrumb.Item>Playlists</Breadcrumb.Item>
                        <Breadcrumb.Item>Schedules</Breadcrumb.Item>
                        <Breadcrumb.Item>Configure</Breadcrumb.Item>
                    </Breadcrumb>
                    <div
                        style={{
                            padding: 24,
                            background: "#fff",
                            minHeight: 700,
                            borderRadius: borderRadiusLG,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div /** Title Div */
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: 20,
                                position: "relative",
                            }}
                        >
                            <h2
                                style={{
                                    paddingLeft: "2.5%",
                                }}
                            >
                                Configure Schedules
                            </h2>
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: 0,
                                    left: "2.5%",
                                    width: "95%",
                                    borderBottom:
                                        "1px solid rgb(190, 190, 190)",
                                }}
                            />
                        </div>
                        <div // Main Content
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "flex-start",
                                marginBottom: 20,
                                height: "100%",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                    width: "60%",
                                    height: "100%",
                                    marginRight: 20,
                                    marginLeft: 20,
                                }}
                            >
                                <div
                                    style={{
                                        width: "92%",
                                        marginLeft: 20,
                                        marginRight: 40,
                                    }}
                                >
                                    <Tabs
                                        centered
                                        defaultActiveKey={1}
                                        items={tabItems}
                                    />
                                </div>
                            </div>
                            <div // Node List
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                    marginBottom: 20,
                                    width: "40%",
                                    position: "relative",
                                    height: "100%",
                                }}
                            >
                                <NodeScheduleDisplay 
                                    nodeList={nodeList}
                                    playlistSchedules={playlistSchedules}
                                />
                            </div>
                        </div>
                    </div>
                </Content>
                <Footer
                    style={{
                        textAlign: "center",
                    }}
                >
                    Display Vantage ©{new Date().getFullYear()}
                </Footer>
            </Layout>
        </Layout>
    );
};

export default NodePlaylistManager;