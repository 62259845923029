import React from "react";
import { useState } from 'react'
import './styles/StartingButtons.css'
import LandingTree from "./LandingTree";
import { useNavigate } from 'react-router-dom';

function StartingButtons(){
    const navigate = useNavigate()
    const navToNodeEnroll = () => {
        navigate('/nodeEnroll')
    }
    const navToPlaylistSetup = () => {
        navigate('/setup')
    }

    const onNewNodeClick = () =>{
        navToNodeEnroll()
    }
    const onNewPlaylistClick = () =>{
        navToPlaylistSetup()
    }


    return(
        <>
            <div>
                <input 
                    onClick = {onNewNodeClick}
                    class ="create-buttons"
                    type="button"      
                    value="Create New Node"
                /> <br></br>
                <input
                    onClick={onNewPlaylistClick}
                    class="create-buttons"
                    type="button"
                    value="Create New Playlist"
                />
            </div>
        </>
    )
}
export default StartingButtons