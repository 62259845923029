import React, { useState, useEffect } from "react";
import {
    Select,
    Table,
} from "antd";

const NodeScheduleDisplay = ({ nodeList, playlistSchedules }) => {
    const [selectedNode, setSelectedNode] = useState(null);
    const [allSchedules, setAllSchedules] = useState([]);
    const [nodeSchedule, setNodeSchedule] = useState([]);

    useEffect(() => {
        console.log(playlistSchedules);
        setAllSchedules(playlistSchedules);
    }, [playlistSchedules]);

    useEffect(() => {
        if (allSchedules && selectedNode) {
            // Filter schedules by selected node
            const newNodeSchedule = allSchedules.filter(
                (schedule) => schedule.node_ID === selectedNode
            );

            // Sort by start time and then end time
            newNodeSchedule.sort((a, b) => {
                if (a.start_time < b.start_time) return -1;
                if (a.start_time > b.start_time) return 1;
                if (a.end_time < b.end_time) return -1;
                if (a.end_time > b.end_time) return 1;
                return 0;
            });
            
            setNodeSchedule(newNodeSchedule);
        }
    }, [allSchedules, selectedNode]);

    const scheduleColumns = [
        {
            title: "Name",
            dataIndex: "playlist_name",
            key: "playlist_name",
        },
        {
            title: "Start Time",
            dataIndex: "start_time",
            key: "start_time",
            render: (text) => <p>{new Date(text).toLocaleString()}</p>,
        },
        {
            title: "End Time",
            dataIndex: "end_time",
            key: "end_time",
            render: (text) => <p>{new Date(text).toLocaleString()}</p>,
        },
    ];

    return (
        <div // Node List
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                marginBottom: 20,
                width: "100%",
                position: "relative",
                height: "100%",
            }}
        >
            <Select
                defaultValue="Select Node"
                style={{
                    width: 200,
                    paddingLeft: "8.33%", // 2.5% divided by 30%
                    marginBottom: 20,
                }}
                onSelect={(value) => setSelectedNode(value)}
            >
                {nodeList.map((node) => {
                    return (
                        <Select.Option value={node.id}>
                            {node.name}
                        </Select.Option>
                    );
                })}
            </Select>
            <div // Current Project Schedule
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: 20,
                    paddingLeft: "8.33%", // 2.5% divided by 30%
                    paddingRight: "8.33%", // 2.5% divided by 30%
                    width: "100%",
                    height: "100%",
                }}
            >
                <h3
                    style={{
                        height: "5%",
                    }}
                >
                    Node's Playlist Schedules
                </h3>
                <Table
                    columns={scheduleColumns}
                    dataSource={nodeSchedule ? nodeSchedule : []}
                    style={{
                        width: "100%",
                        height: "95%",
                    }}
                />
                <div
                    style={{
                        position: "absolute",
                        left: 0,
                        top: "2.5%",
                        height: "95%",
                        borderLeft: "1px solid rgb(190, 190, 190)",
                    }}
                />
            </div>
        </div>
    );
};

export default NodeScheduleDisplay;