// javascript
import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './app';
import './index.css';
import { ChakraProvider, defaultSystem } from '@chakra-ui/react'

const container = document.getElementById('root');
if (container) {
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <ChakraProvider value={defaultSystem}>
                <App />
            </ChakraProvider>
        </React.StrictMode>
    );
} else {
    console.error('Root container not found');
}

// ReactDOM.render(<App />, document.getElementById('root'));
