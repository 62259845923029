import React, { useState, useEffect } from "react";
import PlaylistItem from "./PlaylistItem";
import {
    PlusOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import {
    Select,
    Input,
    Button,
} from "antd";

const PlaylistEditor = ({ playlists, refreshData }) => {
    const [playlistList, setPlaylistList] = useState(playlists);
    const [playlistIndex, setPlaylistIndex] = useState(null);
    const [playlistName, setPlaylistName] = useState("");
    const [fileItems, setFileItems] = useState([]); // Playlist Files

    useEffect(() => {
        // Reset playlistIndex when playlistList changes
        setPlaylistIndex(null);
        console.log(playlistList);
    }, [playlistList]);

    useEffect(() => {
        // Update playlistName and fileItems when playlistIndex changes
        console.log(playlistList);
        console.log(playlistIndex);
        if (playlistIndex !== null && playlistList.length > 0) {
            setPlaylistName(playlistList[playlistIndex - 1].name);
            // Get Playlist Files
            fetchPlaylistFiles(playlistList[playlistIndex - 1].id);
        }
    }, [playlistIndex, playlistList]);

    const fetchPlaylistFiles = (playlistID) => {
        const url = new URL("api/user/playlist/getPlaylistFiles", window.location.origin);
        url.searchParams.append("playlistID", playlistID);
        const sessionToken = localStorage.getItem("sessionToken");
        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionToken}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data.playlistFiles) {
                    setFileItems(data.playlistFiles);
                }
        });
    }

    const onPlaylistSave = () => {
        // Save edited Playlist
        const playlistUrl = new URL("/api/system/playlist/playlist", window.location.origin);
        const sessionToken = localStorage.getItem("sessionToken");
        fetch(playlistUrl, {
            method: "POST",
            body: JSON.stringify({
                playlistId: playlistList[playlistIndex - 1].id,
                playlistName: playlistName,
            }),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionToken}`,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    alert("Failed to save playlist");
                    return;
                }
            });

        // Saved edited playlist files
        const fileUrl = new URL("/api/system/playlist/playlistFile", window.location.origin);
        for (let i = 0, len = fileItems.length; i < len; i++) {
            const item = fileItems[i];
            console.log(item);
            fetch(fileUrl, {
                method: "POST",
                body: JSON.stringify({
                    playlistFileId: Number(item.playlistFileID),
                    fileInterval: Number(item.fileInterval), // Convert to milliseconds
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionToken}`,
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        alert("Failed to save playlist item");
                        return;
                    }
                });
        }
        setPlaylistIndex(null);
        setPlaylistName("");
        setFileItems([]);
        checkToNotifyNodes(playlistList[playlistIndex - 1].id);
        refreshData();
    }

    const onPlaylistDelete = () => {
        // Delete Playlist
        const playlistId = playlistList[playlistIndex - 1].id;
        const playlistUrl = new URL("/api/system/playlist/playlist", window.location.origin);
        const sessionToken = localStorage.getItem("sessionToken");
        fetch(playlistUrl, {
            method: "DELETE",
            body: JSON.stringify({
                playlistId: playlistId,
            }),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionToken}`,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    alert("Failed to delete playlist");
                    return;
                }
            });

        setPlaylistIndex(null);
        setPlaylistName("");
        setFileItems([]);
        checkToNotifyNodes(playlistId);
        refreshData();
    }

    const checkToNotifyNodes = (playlistId) => {
        // TODO Implement
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 20,
                marginLeft: 20,
                width: "100%",
            }}
        >
            <h3>Edit Playlist</h3>
            <div>
                <Select
                    defaultValue="Select Playlist"
                    style={{
                        width: 200,
                        paddingLeft: "8.33%", // 2.5% divided by 30%
                        marginBottom: 20,
                    }}
                    onSelect={(value) => setPlaylistIndex(value)}
                    value={playlistIndex}
                >
                    {playlistList.map((playlist, index) => {
                        return (
                            <Select.Option key={index} value={playlist.id}>
                                {playlist.name}
                            </Select.Option>
                        )
                    })}
                </Select>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 10,
                    width: "100%",
                }}
            >
                <h4
                    style={{
                        width: "20%",
                    }}
                >
                    Playlist Name:
                </h4>
                <Input
                    value={playlistName}
                    onChange={(e) => setPlaylistName(e.target.value)}
                    placeholder={"Insert Name Here"}
                ></Input>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 10,
                    width: "100%",
                    position: "relative",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginBottom: 20,
                        width: "100%",
                    }}
                >
                    <h4
                        style={{
                            width: "20%",
                        }}
                    >
                        Playlist Items:
                    </h4>
                </div>
                {fileItems && fileItems.map((item, index) => {
                    return (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: 10,
                                width: "100%",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "left",
                                    alignItems: "center",
                                    padding: "5px 10px 0px 10px",
                                    width: "30%",
                                }}
                            >
                                <h4>
                                    {item.fileName}
                                </h4>
                            </div>
                            <Input
                                placeholder={"Insert Interval in Seconds"}
                                onChange={(e) => {
                                    const newFileItems = [...fileItems];
                                    if (isNaN(e.target.value)) {
                                        newFileItems[index].fileInterval = 0;
                                    } else if (e.target.value === "") {
                                        newFileItems[index].fileInterval = 0;
                                    } else {
                                        newFileItems[index].fileInterval = e.target.value * 1000;
                                    }
                                    setFileItems(newFileItems);
                                }}
                                value={
                                    item.fileInterval === 0 ? "" :
                                    item.fileInterval / 1000
                                }
                            ></Input>
                        </div>
                    );
                })}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 20,
                        width: "100%",
                        gap: 20,
                    }}
                >
                    <Button
                        style={{
                            width: "25%",
                        }}
                        disabled={fileItems.length === 0 || !playlistName}
                        onClick={onPlaylistSave}
                    >
                        Save
                    </Button>
                    <Button
                        icon={<DeleteOutlined />}
                        disabled={playlistIndex === null}
                        onClick={onPlaylistDelete}
                    ></Button>
                </div>
            </div>
        </div>
    );
};

export default PlaylistEditor;