import React, { useState, useEffect } from "react";
import PlaylistItem from "./PlaylistItem";
import { useNavigate } from 'react-router-dom';
import {
    PlusOutlined,
} from "@ant-design/icons";
import {
    Input,
    Button,
} from "antd";
/**
 * fileItems:
 * [
 *   {
 *      file: File,
 *      index: Number,
 *      interval: Number,
 *   },
 *   ...
 * ]
 */



const PlaylistCreator = ({ rootDir, refreshData }) => {
    const [playlistName, setPlaylistName] = useState("");
    const [fileItems, setFileItems] = useState([]); // Playlist Files

    useEffect(() => {
        console.log(fileItems);
    }, [fileItems]);
    
    const navigate = useNavigate();
    const navToLogin = () => {
        navigate('/nodeEnroll');   
    };

    const sessionToken = localStorage.getItem("sessionToken");

    const getGUID = async () => {
        // Generate a GUID for the playlist ID
        const url = new URL('/api/system/guid/guidGenerator', window.location.origin);
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    primaryAttribute: 'playlist_ID',
                    tableName: "Playlist"
                })
            });

            if (response.status === 406) {
                console.log("Token expired.");
                // Navigate to login page
                navToLogin(); 
            }

            const data = await response.json();
            return data.uuid;  
        } catch (error) {
            console.error("Error fetching GUID:", error);
            return null;
        }    
    }

    const createPlaylist = async () => {
        // Create Playlist
        const playlistId = getGUID();
        const playlistUrl = new URL("/api/system/playlist/playlist", window.location.origin);
        
        const response = await fetch(playlistUrl, {
            method: "PUT",
            body: JSON.stringify({
                playlistId: playlistId,
                playlistDir: rootDir,
                playlistName: playlistName,
            }),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionToken}`,
            },
        })

        if (!response.ok) {
            alert("Failed to create playlist");
            return;
        }

        // Add Playlist Items
        const fileUrl = new URL("/api/system/r2/postR2Object", window.location.origin);
        fileUrl.searchParams.append('domain', "https://displayvantage.com");
        for (let i = 0, len = fileItems.length; i < len; i++) {
            const item = fileItems[i];
            const formData = new FormData();
            formData.append("file", item.file);
            formData.append("fileInterval", Number(item.interval) * 1000); // Convert to milliseconds
            formData.append("fileIndex", i);
            formData.append("playlistId", playlistId);
            const itemResponse = await fetch(fileUrl, {
                method: "POST",
                body: formData,
                headers: {
                    Authorization: `Bearer ${sessionToken}`,
                },
            });

            if (!itemResponse.ok) {
                alert("Failed to add playlist item");
                return;
            }
        }
        setPlaylistName("");
        setFileItems([]);

        refreshData();
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 20,
                marginLeft: 20,
                width: "100%",
            }}
        >
            <h3>Create New Playlist</h3>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 10,
                    width: "100%",
                }}
            >
                <h4
                    style={{
                        width: "20%",
                    }}
                >
                    Playlist Name:
                </h4>
                <Input
                    value={playlistName}
                    onChange={(e) => setPlaylistName(e.target.value)}
                    placeholder={"Insert Name Here"}
                ></Input>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 10,
                    width: "100%",
                    position: "relative",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 20,
                        width: "100%",
                    }}
                >
                    <h4
                        style={{
                            width: "20%",
                        }}
                    >
                        Playlist Items:
                    </h4>
                    <Button
                        icon={<PlusOutlined />}
                        onClick={() => {
                            setFileItems([
                                ...fileItems,
                                {
                                    file: null,
                                    index: fileItems.length,
                                    interval: null,
                                },
                            ]);
                        }}
                    >
                        Add Item
                    </Button>
                </div>
                {fileItems.map((item, index) => {
                    return (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: 10,
                                width: "100%",
                            }}
                        >
                            <PlaylistItem
                                item={item}
                                index={index}
                                fileItems={fileItems}
                                setFileItems={setFileItems}
                            />
                        </div>
                    );
                })}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 20,
                        width: "100%",
                    }}
                >
                    <Button
                        style={{
                            width: "25%",
                        }}
                        disabled={fileItems.length === 0 || !playlistName}
                        onClick={() => createPlaylist()}
                    >
                        Create Playlist
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PlaylistCreator;