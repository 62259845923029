import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from '../../pages/Login';
import RegistrationLogin from '../../pages/RegistrationLogin';
import NodeEnroll from '../../pages/NodeEnroll';
import Dashboard from '../../pages/Dashboard';
import WebSocketComponent from '../../shared/ui/TestWebSocketComponent';
import DigitalSign from '../../pages/DigitalSign/';
import FileUpload from '../../pages/FileUpload';
import PlaylistSetup from '../../pages/PlaylistSetup';
import TestAPI from '../../pages/TestApi';
import NodePlaylistManager from '../../pages/NodePlaylistManager';
import CreateAccount from '../../pages/CreateAccount';
import OnboardPage from '../../pages/Onboarding/OnboardPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<OnboardPage />} />
        <Route path="/registrationLogin" element={<RegistrationLogin />} />
        <Route path="/nodeEnroll" element={<NodeEnroll />} />
        <Route path="/Dashboard/Landing" element={<Dashboard />} />
        <Route path="/websocket" element={<WebSocketComponent />} />
        <Route path="/digitalSign" element={<DigitalSign />} />
        <Route path="/fileUpload" element={<FileUpload />} />
        <Route path="/setup" element={<PlaylistSetup />} />
        <Route path="/testapi" element={<TestAPI />} />
        <Route path="/NodePlaylistManager" element={<NodePlaylistManager />} />
        <Route path="/CreateAccount" element={<CreateAccount />} />
        <Route path="/Login" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;