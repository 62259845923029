import React, { useState } from "react";
import {
    UploadOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import {
    Input,
    Button,
    Upload,
} from "antd";

const PlaylistItem = ({ item, index, fileItems, setFileItems }) => {
    const [interval, setInterval] = useState(item.interval || "");

    const uploadProps = {
        onRemove: (file) => {
            const newFileItems = [...fileItems];
            newFileItems[index].file = null;
            setFileItems(newFileItems);
        },
        beforeUpload: (file) => {
            const newFileItems = [...fileItems];
            newFileItems[index].file = file;
            setFileItems(newFileItems);
            return false;
        },
    };

    const onItemRemove = () => {
        const newFileItems = [...fileItems];
        newFileItems.splice(index, 1);
        for (let i = 0, len = newFileItems.length; i < len; i++) {
            if (newFileItems[i].index !== i) {
                newFileItems[i].index = i;
            }
        }
        setFileItems(newFileItems);
    };

    const onIntervalChange = (e) => {
        setInterval(e.target.value);
    };

    const onIntervalBlur = (e) => {
        const value = e.target.value;
        if (!value) {
            const newFileItems = [...fileItems];
            newFileItems[index].interval = null;
            setFileItems(newFileItems);
        } else if (!isNaN(value) && value.trim() !== "") {
            const newFileItems = [...fileItems];
            newFileItems[index].interval = value;
            setFileItems(newFileItems);
        } else {
            alert("Please enter a valid number");
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                marginBottom: 5,
                width: "100%",
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    gap: 20,
                }}
            >
                <Upload {...uploadProps}>
                    <Button
                        disabled={fileItems[index].file}
                        icon={<UploadOutlined />}
                    >
                        Upload File
                    </Button>
                </Upload>
                <Input
                    placeholder={"Insert Interval in Seconds"}
                    onChange={onIntervalChange}
                    onBlur={onIntervalBlur}
                    value={interval}
                ></Input>
                <Button
                    onClick={onItemRemove}
                    icon={<DeleteOutlined />}
                ></Button>
            </div>
            {/* <p>{fileItems[index].file ? fileItems[index].file.name : ""}</p> */}
        </div>
    );
};

export default PlaylistItem;