import React from 'react'
import { Box, Text, Heading, Center, VStack, Input, HStack, Button, Image} from '@chakra-ui/react'
import { PasswordInput } from '../../../components/ui/password-input'
import { Field } from '../../../components/ui/field';
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { InputGroup } from '../../../components/ui/input-group'
import { Toaster, toaster } from "../../../components/ui/toaster"
import logo from '../../../shared/assets/logo.png'
import { MdOutlineMail } from "react-icons/md"
import { MdOutlineLock } from 'react-icons/md'
import { FaRegUser } from "react-icons/fa"

const CreateAccount = () => {
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordVerify, setPasswordVerify] = useState("");
    const [userNameError, setUserNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [passwordVerifyError, setPasswordVerifyError] = useState("");
    const [message, setMessage] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);
    const navigate = useNavigate();

    const navToDashboard = () =>{
        navigate("/Dashboard/Landing");
    }

    const navToLogin = () =>{
        navigate("/Login")
    }


    // verify session
    useEffect(() => {
        const sessionToken = localStorage.getItem('sessionToken');
        if (sessionToken) {
          const url = new URL('/api/auth/sessionVerify', window.location.origin);
          url.searchParams.append('token', sessionToken);
    
          fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json' },
          })
          .then(response => response.json())
          .then(data => {
            if (data.expired) {
              console.log("Session expired, please log in again.");
            } else {
              navToDashboard();
            }
          })
          .catch(error => {
            console.error("Error verifying session:", error);
          });
        }
    }, []);


    const [checkUsername, checkUsernameToggle] = useState(true);
    const [checkEmail, checkEmailToggle] = useState(true);
    const [checkPassword, checkPasswordToggle] = useState(true);
    const [checkPasswordVerify, checkPasswordVerifyToggle] = useState(true);
    const [userNameValid, setUserNameValid] = useState(false);
    const [emailValid, setEmailValid] = useState(false);
    const [passwordValid, setPasswordValid] = useState(false);
    const [passwordVerifyValid, setPasswordVerifyValid] = useState(false);

    const initialRender = useRef({userNameReady: false, emailReady: false, passwordReady: false, passwordVerifyReady: false});
    const [trigger, setTrigger] = useState(false);

    // checking if username exits
    useEffect(() => {
        setUserNameError("");
        if(initialRender.current["userNameReady"]){
            if(userName === ""){
                setUserNameError("Please enter a username");
                checkUsernameToggle(false);
                setUserNameValid(false);
            }else{
                checkUsernameToggle(true);
                setUserNameValid(true);
            }
        }
        initialRender.current["userNameReady"] = true;
    },[userName, trigger]);

    // checking email is in correct format
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    useEffect(() => {
        setEmailError("");
        if(initialRender.current["emailReady"]){
            if(email === ""){
                setEmailError("Please enter an email");
                checkEmailToggle(false);
                setEmailValid(false);
            }else if(!emailRegex.test(email)){
                setEmailError("Please check email format");
                checkEmailToggle(false);
                setEmailValid(false);
            }else{
                checkEmailToggle(true);
                setEmailValid(true);
            }
        }
        initialRender.current["emailReady"] = true;
    },[email, trigger]);

    // Checking password matches at least 8 characters long and contains at least one capital
    const passwordRegex = /^(?=.*[A-Z])[a-zA-Z\d]{8,}$/;
    useEffect(() => {
        setPasswordError("");
        if(initialRender.current["passwordReady"]){
            if(password === ""){
                setPasswordError("Please enter a password");
                checkPasswordToggle(false);
                setPasswordValid(false);
            }else if(!passwordRegex.test(password)){
                setPasswordError("Please Check password format");
                checkPasswordToggle(false);
                setPasswordValid(false);
            }else{
                checkPasswordToggle(true);
                setPasswordValid(true);
            }
        }
        initialRender.current["passwordReady"] = true;
    },[password, trigger]);

    // makes sures user types in the correct password
    useEffect(() => {
        setPasswordVerifyError("");
        if(initialRender.current["passwordVerifyReady"]){
            if(passwordVerify === ""){
                setPasswordVerifyError("Please verify password");
                checkPasswordVerifyToggle(false);
                setPasswordVerifyValid(false);
            }else{
                if(password != passwordVerify){
                    setPasswordVerifyError("Password does not match");
                    checkPasswordVerifyToggle(false);
                    setPasswordVerifyValid(false);
                }else{
                    checkPasswordVerifyToggle(true);
                    setPasswordVerifyValid(true);
                }
            }
        }
        initialRender.current["passwordVerifyReady"] = true;
    },[passwordVerify, trigger]);


    const userNameRef = useRef(null);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const passwrodVerifyRef = useRef(null);
    const submitButtonRef = useRef(null);

    // handling hitting enter
    useEffect(() => {
        const handleEnterKey = (event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            submitButtonRef.current?.click();
          }
        };
    
        if(userNameRef.current){
            userNameRef.current.addEventListener("keydown", handleEnterKey);
        }
        if (emailRef.current) {
          emailRef.current.addEventListener("keydown", handleEnterKey);
        }
        if (passwordRef.current) {
          passwordRef.current.addEventListener("keydown", handleEnterKey);
        }
        if(passwrodVerifyRef.current){
            passwrodVerifyRef.current.addEventListener("keydown", handleEnterKey);
        }
    
        return () => {
            if(userNameRef.current){
                userNameRef.current.removeEventListener("keydown", handleEnterKey);
            }
            if (emailRef.current) {
                emailRef.current.removeEventListener("keydown", handleEnterKey);
            }
            if (passwordRef.current) {
                passwordRef.current.removeEventListener("keydown", handleEnterKey);
            }
            if(passwrodVerifyRef.current){
                passwrodVerifyRef.current.removeEventListener("keydown", handleEnterKey);
            }
        };
      }, []); 



    //submit form
    const onSubmit = () => {
        if(!userNameValid || !emailValid || !passwordValid || !passwordVerifyValid){
            setTrigger(!trigger);
            return;
        }

        // puts loading message
        toaster.loading({
            title: "Regerstering Account and sending verification email",
            description: "Please wait...",
            type: "loading",
            duration: "5000",
        });
    
        const url = new URL('/api/enroll/putUserRegistration', window.location.origin);
    
        // trying to send verification email
        fetch(url, {
          method: 'POST',
          headers: { 
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: email.toLowerCase()
          })
        })
        .then(response => {
            if (!response.ok){
                if (response.status == 400){
                    throw new Error ("Email is required")
                }
                else if (response.status == 410){
                    throw new Error ("User with this email already exists")
                }
                else if (response.status == 500){
                    throw new Error ("Failed to send email please try again")
                }else if(response.status == 403){
                    throw new Error ("IP bloced due to excesive requests");
                }else if(response.status == 429){
                    throw new Error ("Too many request please wait a few minutes before trying again");
                }
                else {
                    throw new Error ("Error sending verification email");
                }
            }

            return response.json();
        })
        .then(data => {
            toaster.remove();
            console.log(`returned good status ${data.status}`)
            toaster.create({
                title: "Success verification Email sent",
                description: "Please go verify email and return to login",
                type: 'success',
            })
            setEmailSent(true);
        })
        .catch(error => {
            toaster.remove();
            setMessage(error.message);
            toaster.create({
                title: "Error",
                description: `${error.message}`,
                type: "error",
            })
            setEmailSent(false);
        });
    };

    // TODO if wanting auto login
    // this was an attempt for me to auto login after verification
    /*
    useEffect(() => {
        // check if user email is verified
        const url = new URL('/api/enroll/postCheckUserVerification', window.location.origin);
        
        // trying to chek if email is verified
        fetch(url, {
            method: 'POST',
            headers: { 
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email.toLowerCase()
            })
        })
        .then(response => {
            if (!response.ok){
                if (response.status == 400){
                    throw new Error ("Email is required")
                }
                else if(response.status == 401){
                    throw new Error ("User is not verified")
                }
                else {
                    throw new Error ("Error checking email verification");
                }
            }
            return response.json();
        })
        .then(data => {
            setEmailVerified(true);
        })
        .catch(error => {
            setMessage(error.message);
            setEmailVerified(false);
        });
    })
    */

    // calls creteUser once email is sent
    useEffect(() => {
        if(emailSent){
            createUser()
        }
    },[emailSent]);


    const [usernameCreated, setUserNameCreated] = useState(false);
    const [passwordCreated, setPasswordCreated] = useState(false);

    // Puts the user in the users db adds the password and displayname
    async function createUser(){

          const urlUsername = new URL('/api/enroll/postSetUsernameAndPassword', window.location.origin);
      
          fetch(urlUsername, {
            method: 'POST',
            headers: { 
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              username: userName,
              email: email.toLowerCase(),
              password: password,
            })
          })
          .then(response => {
              if (!response.ok){
                  if (response.status == 400){
                      throw new Error ("UserName must be non-empty and less than 35 characters")
                  }
                  else if(response.status == 401){
                    throw new Error ("Password must match the requirements");
                  }
                  else if(response.status == 403){
                    throw new Error ("User with this email already Exists")
                  }
                  else {
                      throw new Error ("Error setting username");
                  }
              }
  
              return response.json();
          })
          .then(data => {
              setUserNameCreated(true);
              setTimeout(navToLogin, 5000);
          })
          .catch(error => {
              setMessage(error.message);
              toaster.create({
                  title: "Error",
                  description: `${error.message}`,
                  type: "error",
              })
              setUserNameCreated(false)
          });
    }

    // TODO : This is not currently working
    // once the the user is created it logs them in
    /*
    useEffect (() => {
        if(usernameCreated && passwordCreated){
            const url = new URL('/api/auth/loginVerify', window.location.origin);

            fetch(url, {
            method: 'POST',
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email.toLowerCase(),
                password: password
            })
            })
            .then(response => response.json())
            .then(data => {
            if (data.token) {
                console.log("trying to login")
                if (data.token) {
                    localStorage.setItem('sessionToken', data.token);
                    navToDashboard();
                  } else {
                    setPasswordError("Invalid email or password");
                  }
            } else {
                setPasswordError("Invalid email or password");
            }
            })
            .catch(error => {
            setPasswordError("Invalid email or password");
            console.log(error)
            toaster.create({
                title: "Error",
                description: "Invalid email or password",
                type: "error",
            })
            });
        }
    }, [usernameCreated, passwordCreated])
    */


  return (
    <Box bg={"gray.100"} minH={"100vh"} p={6} w={"full"} alignContent={"center"}>
        <Center>
            <HStack maxW={"3/6"} minW={"3/6"} maxH={"4/5"} minH={"4/5"} style={{backgroundColor: "#011936"}}
             rounded={"4xl"} gap={0} p={0} shadow={"xl"}>
                <Box style={{backgroundColor: "#011936"}} minW={"3/5"} rounded={"4xl"} paddingBottom={4}>
                    <Heading as={"h1"} align={"center"} fontSize={28} p={8} fontFamily={"Esteban"}>
                        Create Account
                    </Heading>
                    <VStack gap={4}  paddingLeft={8} align={"flex-start"} paddingRight={16}>
                            <Field required label="Username" invalid={!checkUsername} errorText={userNameError}>
                                <InputGroup startElement={<FaRegUser />} w={"full"}>
                                    <Input placeholder={"Username"} bg={"gray.100"} rounded={"xl"} ref={userNameRef}
                                        p={4} color={"black"} fontSize={20} onChange={ev => setUserName(ev.target.value)}>            
                                    </Input>
                                </InputGroup>
                            </Field>
                            <Field required label="Email" invalid={!checkEmail} errorText={emailError}>
                                <InputGroup startElement={<MdOutlineMail />} w={"full"}>
                                    <Input placeholder={"Email"} bg={"gray.100"} rounded={"xl"} ref={emailRef}
                                        p={4} color={"black"} fontSize={20} onChange={ev => setEmail(ev.target.value)}>   
                                    </Input>
                                </InputGroup>
                            </Field>
                            <Field required label="Password" invalid={!checkPassword} errorText={passwordError}>
                                <Text fontSize={12} color={"gray.200"}>
                                    •Password must be minimum 8 characters
                                </Text>
                                <Text fontSize={12} color={"gray.200"}>
                                    •Password must contain a capital letter
                                </Text>
                                <InputGroup startElement={<MdOutlineLock />} w={'full'}>
                                    <PasswordInput placeholder={"Password"} bg={"gray.100"} rounded={"xl"} ref={passwordRef}
                                        p={4} color={"black"} fontSize={20} onChange={ev => setPassword(ev.target.value)}>  
                                    </PasswordInput>
                                </InputGroup>
                            </Field>
                                <Field required label="Password verify" invalid={!checkPasswordVerify} errorText={passwordVerifyError}>
                                    <InputGroup startElement={<MdOutlineLock />} w={'full'}>
                                        <PasswordInput placeholder={"Re-Enter Password"} bg={"gray.100"} rounded={"xl"} ref={passwrodVerifyRef}
                                            p={4} color={"black"} fontSize={20} onChange={ev => setPasswordVerify(ev.target.value)}>    
                                        </PasswordInput>
                                    </InputGroup>
                                </Field>   
                            <Button rounded={"xl"} style={{ backgroundColor: "#f9DC5C"}} fontFamily={"Esteban"} fontSize={20}
                            onClick={onSubmit} color={'black'} ref={submitButtonRef}>
                                Create Account
                            </Button>
                            <Toaster />
                            <Link to={'/'}>
                                <Text _hover={{color: "#f9DC5C"}}>
                                    &#8592;Back
                                </Text>
                            </Link>
                    </VStack>
                </Box>
                <Box style={{backgroundColor: "#B1BFFF"}} rounded={"4xl"} h={"calc(100vh*.9)"} alignContent={"center"}>
                    <Image src={logo} />
                </Box>
            </HStack>
        </Center>
    </Box>
  )
}

export default CreateAccount