import React from 'react'
import { Flex, Text, Button, Box, Image, VStack } from "@chakra-ui/react"
import logo from '../../shared/assets/logo.png'
import DV from '../../shared/assets/DVImage.svg'
import { useNavigate } from 'react-router-dom';
import RegistrationLogin from '../RegistrationLogin/ui/RegistrationLogin';
import { useState, useEffect } from 'react'


const OnboardPage = () => {
    const [regLog, toggleRegLog] = useState(false);

    return(
        <>
            <Box pos={'relative'} minW={'lvw'} minH={'lvh'}>
                <Onboard useVal={regLog} useSet={toggleRegLog} />
                {regLog ? <RegistrationLogin useVal={regLog} useSet={toggleRegLog} /> : <></>}
            </Box>
        </>
    )
}

export default OnboardPage

function Onboard({useVal, useSet}) {
    const navigate = useNavigate();

    const navToLogin = () => {
        navigate('/Login');
    };
    const openRegistrationLogin = () => {
        useSet(!useVal)
        console.log("clicked onboard")
    }

  return (
    <>
    <Box bg={"gray.100"} minH={"full"} minW={'full'} p={6} pos={'absolute'}>
        <Flex w="full" justifyContent={"space-between"} alignItems={"center"} flexDir={{xl: "row", mdDown: "column"}}>
            <Flex align={"center"} gap={"6"} flexDir={{xl: "row", mdDown: "column"}}>
                <Image src={logo} h={20}/>
                <Text fontFamily={"Esteban"} fontSize={40}  color={"black"}>
                    Display Vantage
                </Text>
            </Flex>
            <Flex  gap="4">
                <Button size={24} p={2} bg={"gray.100"} color={'#465362'}>
                    Learn More
                </Button>
                <Button size={24} p={2} bg={"gray.100"} style={{borderColor: "#B1BFFF", color: "#B1BFFF"}} borderWidth={3}>
                    Explore Options
                </Button>
                <Button size={24} p={2} style={{backgroundColor: "#011936", color: "white"}} onClick={navToLogin}>
                    Login
                </Button>
            </Flex>
        </Flex>
        <Flex paddingLeft={20} paddingRight={10} paddingTop={{xl: "12", lg: "10", smDown: "8"}} gap={{xl: "16", lg: "12", smDown: "8"}} 
         align={"center"} justifyContent={"space-around"} flexDir={{xl: "row", mdDown: "column"}}>
            <VStack gap={{xl: "20", md: "20", smDown: "8"}}>
                <Box>
                    <Text textStyle={{xl: "5xl", lg: "2xl", md: "xl", smDown: "3xl"}} color={"black"} fontWeight={"bold"} maxW={"20rem"} style={{lineHeight: "4rem"}}>
                        Digital Signage made easy and accessible for{"\n"}
                    <Text as={"span"} style={{color: "#ED254E"}}>
                        Everyone
                    </Text>
                    </Text>
                </Box>
                <Text textStyle={{xl: "2xl", lg: "xl", smDown: "xl"}} style={{color: "#465362"}} maxW={"36rem"} >
                    Create and display digital signs to all your businesses screens with an easy to use web appliction to edit, manage and display your content.
                </Text>
            </VStack>
            <Box>
                <Image src={DV} htmlWidth={"480rem"} />
            </Box>
        </Flex>
        <Flex justifyContent={"end"} paddingRight={{xl: "52", lg: "32", smDown: "12"}} paddingTop={{xl: "14", lg: "8", smDown: "6"}}>
            <Button textStyle={{xl: "5xl", lg: "2xl", sm: "xl"}} style={{ backgroundColor: "#f9DC5C"}} color={'black'} shadow={'lg'}
                p={{xl: "10", lg: "8", smDown: "4"}} rounded={"xl"} marginBottom={{xl: "10", lg: "8", smDown: "4"}} onClick={openRegistrationLogin}>
                Register This Screen
            </Button>
        </Flex>
    </Box>
    </>
  )
}